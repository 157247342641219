// Generated by ReScript, PLEASE EDIT WITH CARE


function parse(str) {
  switch (str) {
    case "ALT_TEXT" :
        return "ALT_TEXT";
    case "SUMMARY" :
        return "SUMMARY";
    default:
      return ;
  }
}

function serialize(s) {
  return s;
}

export {
  parse ,
  serialize ,
}
/* No side effect */
